<template>
    <div class="container" v-if="state">
        <div>
            <p style="text-align: center;color: #fff;font-size: 65px;margin-top: 35px;">分享讓更多人爲我打氣！</p>
        </div>
        <main style="margin-left: auto;margin-right: auto;margin-top:50px;">
            <div class="main_div" style="">
                <div class="main_div_yi">
                     <i>{{form.real_name}}</i>&nbsp;&nbsp;先生/女士
                </div>
                <div class="main_div_er" v-if="!form.category_name">
                    <p style="width: 45.8vw;height: 2.5vh;">感謝您就&nbsp;&nbsp;<i>{{form.agency_name}}</i>&nbsp;&nbsp;項目捐出&nbsp;&nbsp;<i>{{form.amount}}</i>&nbsp;&nbsp;,捐款已</p>
                    <p style="width: 45.8vw;height: 4.5vh;">交由&nbsp;&nbsp;<i>{{form.agency_name}}</i>&nbsp;&nbsp;做統一管理及捐贈。</p>
                    <p>在此，我們祝福您平安一生、健康一世、快樂一輩子</p>
                </div>
                <div class="main_div_er" v-else>
                    <p style="width: 45.8vw;height: 2.5vh;">感謝您就&nbsp;&nbsp;<i>{{form.category_name}}</i>&nbsp;&nbsp;分類項目捐出&nbsp;&nbsp;<i>{{form.amount}}</i>&nbsp;&nbsp;在此，</p>
                    <p>我們祝福您平安一生、健康一世、快樂一輩子</p>
                </div>
                <div class="main_div_san">
                    <p style="height:4vh;line-height:8vh;" v-if="!form.category_name">iMeddy&nbsp;及&nbsp;&nbsp;<i>{{form.agency_name}}</i>&nbsp;&nbsp;全體同仁</p>
                    <p style="height:4vh;line-height:8vh;" v-else>iMeddy全體同仁</p>
                    <p style="height:4vh;line-height:5vh;">Signature photo</p>
                    <p style="height:4vh;line-height:2vh;">&nbsp;&nbsp;<i>{{form.date}}</i>&nbsp;&nbsp;</p>
                </div>
            </div>
            <br />
           <div class="group">
<!--                <img src="../assets/img/Group1.png" alt="" class="share-link" />-->
<!--                <img src="../assets/img/Group2.png" alt="" class="share-link" />-->
<!--                <img src="../assets/img/Group3.png" alt="" class="share-link" />-->
<!--                <img src="../assets/img/Group4.png" alt="" class="share-link" />-->
               <facebook-button class="share-button--painted" :url="url" />
               <whats-app-button class="share-button--painted" :url="url" />
               <weibo-button class="share-button--painted" :url="url" />
               <twitter-button class="share-button--painted" :url="url" />
           </div>
           <br />
           <div>
            <router-link class="link" to="/" >回到主页</router-link>
        </div>
        </main>
    </div>
    <div class="container" v-else></div>
</template>

<script>
// TODO: 还没有进行数据绑定。
import api from '@/api';
import { request } from 'http';
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
import WeiboButton from "vue-share-buttons/src/components/WeiboButton";
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";

export default {
    components: {
        FacebookButton,
        WhatsAppButton,
        WeiboButton,
        TwitterButton
    },
    data() {
        return {
           state:true,
           url: undefined,
           form:{
            'agency_name': '',
            'amount': '',
            'date': "",
            'fundraise_name':"",
            'real_name': "",
            'category_name':'',
           }
        };
    },
    methods: {},
    created:function (){
            var url = window.location.href; //获取url中"?"符后的字串
            this.url =url;
            var cs = url.split('?')[1];
            var css = cs.split('&');
            var paymentId = css[0].split('=')[1];
            var token = css[1].split('=')[1];
            var PayerID = css[2].split('=')[1];
            if(!this.$route.params.id || !paymentId || !PayerID){
                this.$message.error('参数异常！')
                return false;
            }
            api.paypal.payments(this.$route.params.id,paymentId,PayerID).then(request => {
                if(request.code === 200){
                    console.log(request.data);
                    this.state = true;
                    this.form.agency_name = request.data.agencyName;
                    this.form.amount = request.data.amount;
                    this.form.date = request.data.date;
                    this.form.fundraise_name = request.data.fundraiseName;
                    this.form.real_name = request.data.realName;
                    this.form.category_name = request.data.categoryName;
                }else{
                    this.$message.error('请求异常，请重新刷新！')
                }
            }).catch(error => {
                console.log(error);
            });
    }
};
</script>

<style lang="scss" scoped>
i{
    color: #fff;
    text-decoration: underline;
}
.group{
    img{
        width:60px;
        margin-left:8px;
    }
    text-align:center;
}
.share-container {
    flex-grow: 1;
    flex-basis: 200px;
    min-width: 200px;
    max-width: 500px;
    margin-top:20px;
    padding: 16px;
    & > div {
        margin-bottom: 16px;
    }
    .h1 {
        font-size: 40px;
        font-weight: 600;
        color: $green-500;
    }
    .h2 {
        font-size: 28px;
        font-weight: 600;
        color: $green-500;
    }
    .note {
        font-size: 18px;
        color: $gray-500;
    }
    .share-links {
        @include fr-b-c;
        .share-link {
            width: 20%;
            height: 100px;
            border-radius: 50%;
            background-color: green;
        }
    }
}

.share-button--painted {
    background-color: green;
}

.link{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 160px;
    height: 35px;
    background: #ff6a6d;
    text-align: center;
    line-height: 35px;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    font-family: "黑体";
    margin-top: -35px;
    text-decoration: none;
}
.container {
    min-height: calc(100vh - 48px);
    background-image: url("../assets/img/new-donation-background.png");
    background-size: cover;
    background-position: center;
    padding: 96px 16px 16px;
    // @include fr-c-c;
}
.main_div{
    background-image: url("../assets/img/Group_yi.png");
    width: 42.5vw;
    min-height: 42vh;
}
.main_div_yi{
    color:#fff;
    width: 42.5vw;
    padding-top: 3vh;
    margin-left:1.7vh;
    height:10vh;
}
.main_div_er{
    p{
        color:#fff;
    }
    width: 42.5vw;
    margin-left:1.7vh;
    height:20vh;
    line-height:10vh;
    text-align:center;
}

.main_div_san{
    p{
        color:#fff;
    }
    width: 42.5vw;
    height:12vh;
    line-height: 1vh;
    text-align:right;
    margin-left:-10px;
}
main {
    position: relative;
    background-color: $white;
    width: 44.5vw;
    max-width: 900px;
    height:61vh;
    min-height: 50vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 20px 20px 88px;
    gap: 16px;
}
</style>